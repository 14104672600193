import {
  IonAvatar,
  IonButton,
  IonCol,
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonNote,
  IonRow,
} from "@ionic/react";
import { useObserver } from "mobx-react-lite";

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import {
  buildOutline,
  heartOutline,
  heartSharp,
  logOutOutline,
  mailOutline,
  mailSharp,
  paperPlaneOutline,
  paperPlaneSharp,
  personCircleOutline,
  todayOutline,
} from "ionicons/icons";
import "./Menu.css";
import { useStore } from "../hooks/use-store";

const Menu: React.FC = () => {
  const location = useLocation();
  const { _user } = useStore();

  useEffect(() => {
    _user.setMenu(0);
  }, []);

  return useObserver(() => (
    <IonMenu
      onIonWillOpen={() => {
        _user.setMenuClass("spin");
      }}
      onIonWillClose={() => {
        _user.setMenuClass("spin-c");
      }}
      contentId="main"
      type="overlay"
    >
      <IonContent>
        <IonList id="inbox-list">
          {_user.appPages.map(
            (
              appPage: {
                url: string | undefined;
                icon: string | undefined;
                title: React.ReactNode;
              },
              index: string | number | null | undefined
            ) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    className={
                      location.pathname === appPage.url ? "selected" : ""
                    }
                    routerLink={appPage.url}
                    routerDirection="forward"
                    lines="none"
                    detail={false}
                  >
                    <IonIcon slot="start" icon={appPage.icon} />
                    <IonLabel>{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            }
          )}
        </IonList>
      </IonContent>
    </IonMenu>
  ));
};

export default Menu;
