import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonSplitPane,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/float-elements.css";
/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/typography.css";
import { documentTextOutline, homeOutline } from "ionicons/icons";
import { useObserver } from "mobx-react-lite";
import React from "react";
import { Redirect, Route } from "react-router";
import AuthRoutes from "./components/AuthRoutes";
import Menu from "./components/Menu";
import MenuGuest from "./components/Menu/MenuGuest";
import RedirectToLogin from "./components/RedirectToLogin";
import { useStore } from "./hooks/use-store";
import AddPurchase from "./pages/AddPurchase";
import DeletePurchase from "./pages/DeletePurchase";
import CardData from "./pages/cards/cardData";
import About from "./pages/About";
import P914 from "./pages/Guest/p914";
import Home from "./pages/Home";
import HomeGuest from "./pages/HomeGuest";
import Lic from "./pages/LIC/Lic";
import Plan914 from "./pages/LIC/Plan914";
import Login from "./pages/Login";
import ManualEntry from "./pages/ManualEntry";
import Register from "./pages/Register";
import Sales from "./pages/Sales";
import SendOTP from "./pages/SendOTP";
import UserProfile from "./pages/profile/UserProfile";
/* Theme variables */
import "./theme/variables.css";
import VerifyOTP from "./pages/VerifyOTP";
import ForgotOTP from "./pages/ForgotOTP";
import ResetPassword from "./pages/ResetPassword";
import VerifyFOTP from "./pages/VerifyFOTP";

const InnerApp: React.FC = () => {
  const { _user } = useStore();
  /*  if (window) {
    window.console.log = window.console.warn = window.console.info = function () {
    };
  }*/
  return useObserver(() => (
    <IonApp>
      <IonReactRouter>
        {_user.authCheckComplete ? (
          <IonSplitPane contentId="main">
            <Menu />
            <IonRouterOutlet id="main">
              <Redirect exact path="/" to="/home" />
              <Route path="/home" component={Home} />
              <Route path="/sales" component={Sales} />
              <Route path="/manual" component={ManualEntry} />
              <Route path="/addPurchase" component={AddPurchase} />
              <Route path="/deletePurchase" component={DeletePurchase} />
              <Route
                path="/logout"
                render={() => {
                  return <RedirectToLogin />;
                }}
              />
            </IonRouterOutlet>
          </IonSplitPane>
        ) : (
          <IonSplitPane contentId="main">
            {/*  <MenuGuest />*/}
            <IonRouterOutlet id="main">
              <Redirect exact path="/" to="/login" />
              <Route path="/home" component={P914} />
              <Route path="/plan/:id" component={P914} />
              <Route path="/otp" component={SendOTP} />
              <Route path="/verifyOTP" component={VerifyOTP} />
              <Route path="/register" component={Register} />
              <Route path="/login" component={Login} />
              <Route path="/forgot" component={ForgotOTP} />
              <Route path="/reset" component={ResetPassword} />
              <Route path="/verifyFOTP" component={VerifyFOTP} />
            </IonRouterOutlet>
          </IonSplitPane>
        )}
      </IonReactRouter>
    </IonApp>
  ));
};
const App: React.FC = () => {
  return <InnerApp />;
};
export default App;
