import React, { useEffect, useContext } from "react";
import { IonRouterContext } from "@ionic/react";
import { useStore } from "../hooks/use-store";

const RedirectToLogin: React.FC = () => {
  const { _user } = useStore();
  const ionRouterContext = useContext(IonRouterContext);
  useEffect(() => {
    _user.doLogout().then(() => ionRouterContext.push("/login"));
  }, []);
  return null;
};

export default RedirectToLogin;
