import { ErrorMessage } from "@hookform/error-message";
import { IonItem, IonLabel, IonSelect, IonSelectOption } from "@ionic/react";
import React from "react";
import { Controller } from "react-hook-form";

interface Sprops {
  name: string;
  control: any;
  errors: any;
  required?: boolean;
  fieldName: string;
  defaultValue?: any;
  options: any;
}

interface Options {
  label: string;
  value: number;
}

const Sselect: React.FC<Sprops> = ({
  fieldName,
  control,
  name,
  errors,
  required,
  defaultValue,
  options,
}) => {
  const showErr = (_fieldName: string) => {
    return (
      <ErrorMessage
        errors={errors}
        name={_fieldName}
        as={<div className="l-label" />}
      />
    );
  };

  let obj: any = {};
  if (required) {
    obj.required = "required field";
  }
  const compareWith = (o1: Options, o2: Options) => {
    return o1 && o2 ? o1.value === o2.value : o1 === o2;
  };

  return (
    <IonItem className="ion-item">
      <IonLabel
        className={obj.required ? "required" : "notreq"}
        color="medium"
        position="floating"
      >
        {fieldName}
        {showErr(name)}
      </IonLabel>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        rules={obj}
        render={({ onChange, onBlur, value, name }) => (
          <IonSelect
            onIonBlur={onBlur}
            onIonChange={(e) => {
              onChange(e.detail.value);
            }}
            value={value}
            name={name}
            compareWith={compareWith}
          >
            {options.map((option: any, i: number) => (
              <IonSelectOption key={i} value={option}>
                {option.label}
              </IonSelectOption>
            ))}
          </IonSelect>
        )}
      />
    </IonItem>
  );
};

export default Sselect;
