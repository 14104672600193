import { Plugins } from "@capacitor/core";
import { RefresherEventDetail } from "@ionic/core";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItemDivider,
  IonList,
  IonLoading,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRouterContext,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import { chevronDownCircleOutline, trendingUpOutline } from "ionicons/icons";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Header from "../components/Header";
import SaleTable from "../components/SaleTable";
import Sdate from "../components/SDate";
import Sinput from "../components/SInput";
import Sselect from "../components/SSelect";
import Table from "../components/Table";
import { useStore } from "../hooks/use-store";
import sAxios from "../store/helpers/axios";
import stats from "../store/helpers/const";
import { toCurrency } from "../store/helpers/functions";
import { toast } from "../store/helpers/toast";
import "./common.css";

const { Storage } = Plugins;
const DeletePurchase: React.FC = () => {
  let history = useHistory();
  const { _user } = useStore();
  const [table, setTable] = useState<null | {
    email: any;
    timestamp: any;
    purchase_id: any;
  }>(null);
  const {
    handleSubmit,
    register,
    control,
    errors,
    setValue,
    getValues,
  } = useForm({});
  const [isProgressing, setProgressing] = useState(false);
  const [hide, setHide] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [id, setId] = useState<String | null>();
  const [ref, setRef] = useState<String>();
  const ionRouterContext = useContext(IonRouterContext);

  const onSubmit = () => {
    new Promise((resolve, reject) => {
      sAxios
        .post("/deleteData", {
          id: id,
        })
        .then((response) => {
          console.log("response", response);
          if (response.status == 200 && response.data.status == true) {
            setTable(null);
            setId(null);
            toast("deleted successfully");
            resolve("done!");
          } else {
            toast("failed to fetch!!!");
          }
        })
        .catch((err: any) => {
          toast(err);
          reject(err);
        });
    });
  };

  let getData = (data: any) => {
    setSubmitted(false);
    return new Promise((resolve, reject) => {
      sAxios
        .post("/getData", {
          ref: getValues("ref"),
        })
        .then((response) => {
          console.log("response", response);
          if (response.status == 200 && response.data.status == true) {
            setSubmitted(true);
            setTable(response.data.payload);
            setId(response.data.message);
            resolve("done!");
          } else {
            toast("failed to fetch!!!");
          }
        })
        .catch((err: any) => {
          toast(err);
          reject(err);
        });
    });
  };

  return (
    <IonPage>
      <Header title="Manual Sale Entry" />
      <IonContent scrollX={true}>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={isProgressing}
          message={"Please wait..."}
          spinner="lines"
        />
        <IonGrid fixed className="s-grid">
          <IonRow className="s-row" id="mh-300">
            <IonCol className="s-col">
              <div className="outbox">
                <div className="innerbox">
                  <div className="innercontent">
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="w-100 form"
                    >
                      <IonRow className="ion-justify-content-around">
                        <IonCol>
                          <IonList
                            lines="full"
                            className="ion-align-items-stretch"
                          >
                            <Sinput
                              name={"ref"}
                              control={control}
                              errors={errors}
                              required={true}
                              fieldName="ref/email"
                              inputmode="text"
                            />
                          </IonList>
                        </IonCol>
                      </IonRow>
                      <IonItemDivider />
                      {table?.email ? (
                        <>
                          <IonRow className="ion-justify-content-around">
                            {id ? <b>{id}</b> : ""}
                          </IonRow>
                          <IonRow className="ion-justify-content-around">
                            {isSubmitted ? <Table info={table} /> : ""}
                          </IonRow>
                        </>
                      ) : (
                        <>{isSubmitted ? <h4> No purchase found</h4> : ""}</>
                      )}
                      <IonRow className="ion-justify-content-center">
                        <IonCol>
                          <IonButton
                            className={hide ? "ion-hide" : ""}
                            fill="solid"
                            onClick={getData}
                            size="small"
                            expand="block"
                            disabled={isProgressing}
                          >
                            {isProgressing ? (
                              <IonSpinner name="dots" />
                            ) : (
                              <>
                                <IonIcon slot="end" icon={trendingUpOutline} />
                                query
                              </>
                            )}
                          </IonButton>
                        </IonCol>
                        <IonCol>
                          <IonButton
                            className={hide ? "ion-hide" : ""}
                            fill="solid"
                            type="submit"
                            size="small"
                            expand="block"
                            disabled={isProgressing}
                          >
                            {isProgressing ? (
                              <IonSpinner name="dots" />
                            ) : (
                              <>
                                <IonIcon slot="end" icon={trendingUpOutline} />
                                delete
                              </>
                            )}
                          </IonButton>
                        </IonCol>
                      </IonRow>
                    </form>
                  </div>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default DeletePurchase;
