import { Plugins } from "@capacitor/core";
import axios from "axios";
import stats from "./const";
import { toast } from "./toast";

const { Storage } = Plugins;
const service = axios.create({
  timeout: 10000,
  baseURL: stats.server,
});

service.interceptors.request.use(
  (config) => {
    Storage.get({ key: "token" }).then((data: { value: string | null }) => {
      const token = data.value ?? "n";
      config.headers["Authorization"] = "Bearer " + token;
    });
    config.withCredentials = false;
    config.headers["Access-Control-Allow-Origin"] = "*";
    config.headers["Access-Control-Allow-Methods"] =
      "Access-Control-Allow-Methods";
    console.log("Starting Request--------------------", config);
    return config;
  },
  (error) => {
    throw error;
  }
);

service.interceptors.response.use(
  function (response) {
    console.log("Response:------------------------", response);
    return response;
  },
  function (error) {
    if (401 === error?.response?.status) {
      toast("Session Expired").then(() => {
        Storage.clear().then(() => (window.location.href = "/"));
      });
    } else {
      return Promise.reject(error);
    }
  }
);
export default service;
