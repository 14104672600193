import { IonText } from "@ionic/react";
import React from "react";

const RecaptchaInfo: React.FC = () => {
  return (
    <p className="t-small">
      This app is protected by reCAPTCHA and the Google
      <a href="https://policies.google.com/privacy">Privacy Policy</a> and
      <a href="https://policies.google.com/terms">Terms of Service</a> apply.
    </p>
  );
};
export default RecaptchaInfo;
