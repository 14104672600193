import { Plugins } from "@capacitor/core";
// set the default values for the controls
import { ErrorMessage } from "@hookform/error-message";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import Sinput from "../components/SInput";
import { useStore } from "../hooks/use-store";
import { UserModel } from "../model/UserModel";
import axios from "../store/helpers/axios";
import { toast } from "../store/helpers/toast";
import "./common.css";

const { Storage } = Plugins;
const Register: React.FC = () => {
  const { _user } = useStore();
  const [mobile, setMobile] = useState("");
  const { handleSubmit, control, errors } = useForm();
  const [isProgressing, setProgressing] = useState(false);
  const history = useHistory();
  useEffect(() => {
    Storage.get({ key: "page" }).then((data: any) => {
      console.log("page is-->", data.value);
      data.value = "register";
      switch (data.value) {
        case "verifyOTP": {
          Storage.clear().then(() => history.push("/otp"));
          break;
        }
        case "register": {
          Storage.get({ key: "mobile" }).then((data: any) => {
            console.log(
              "useEffect has been called, mobile is !",
              JSON.parse(data.value)
            );
            data.value = "6238931626";
            if (data.value) {
              setMobile(data.value);
            } else {
              Storage.clear().then(() => history.push("/otp"));
            }
          });
          break;
        }
        default: {
          Storage.clear().then(() => history.push("/otp"));
          break;
        }
      }
    });
  }, []);
  const _doLogin = async (data: any) => {
    setProgressing(true);
    axios
      .post("/signup", {
        mobile: data.mobile,
        name: data.name,
        email: data.email,
        password: data.password,
      })
      .then((response: any) => {
        if (response.data.status) {
          let user: UserModel = {
            id: response.data.payload.id,
            profilePic: response.data.payload.profilePic,
            name: response.data.payload.name,
            email: response.data.payload.email,
            mobile: response.data.payload.mobile,
            status: response.data.payload.status,
            role_id: response.data.payload.role_id,
            uid: response.data.payload.uid,
          };

          Storage.clear()
            .then(() => {
              _user.setUser(user);
              Storage.set({
                key: "token",
                value: response.data.token,
              });
            })
            .then(() => {
              toast("successfully created").then(() => {
                history.replace("/tabs/home");
              });
            });

          // console.log("user", user);
        } else {
          toast(response.data.message);
        }
      })
      .catch((err: any) => {
        toast(err);
        // this.errors = err.response && err.response.body && err.response.body.errors;
        throw err;
      })
      .finally(() => {
        setProgressing(false);
      });
  };

  const onSubmit = (data: any) => {
    // alert(JSON.stringify(data));
    _doLogin(data);
  };
  return useObserver(() => (
    <IonPage>
      <IonContent>
        <IonGrid fixed className="h-100">
          <IonRow className="h-100">
            <IonCol className="ion-justify-content-center  s-column">
              <IonRow className="ion-align-items-stretch">
                <IonCol size="12">
                  <div className="ion-text-center">
                    <img
                      className="logo-c ion-justify-content-center"
                      src="assets/logo/scs.svg"
                      alt="scs logo"
                    />
                  </div>
                </IonCol>
              </IonRow>
              <IonRow className="ion-align-items-stretch ion-nowrap">
                <IonCol size="12">
                  <div className="center-text">
                    <span>Create Account</span>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="ion-align-self-stretch">
                  <form onSubmit={handleSubmit(onSubmit)} autoComplete="on">
                    <IonRow className="ion-align-items-stretch">
                      <IonCol className="ion-align-self-stretch">
                        <IonList lines="full">
                          <IonItem className="ion-item">
                            <IonLabel color="dark" position={"fixed"}>
                              Your Mobile
                            </IonLabel>
                            <Sinput
                              name="mobile"
                              control={control}
                              fieldName="User Name"
                              inputmode="text"
                              errors={errors}
                            />
                          </IonItem>

                          <Sinput
                            name="name"
                            control={control}
                            errors={errors}
                            min={5}
                            max={50}
                            fieldName="User Name"
                            inputmode="text"
                          />

                          <Sinput
                            name="email"
                            control={control}
                            errors={errors}
                            fieldName="Email"
                            inputmode="email"
                          />
                          <Sinput
                            name="password"
                            control={control}
                            errors={errors}
                            fieldName="Passsword"
                            inputmode="password"
                          />
                        </IonList>
                      </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-stretch">
                      <IonCol>
                        <IonButton
                          fill="solid"
                          type="submit"
                          size="small"
                          expand="block"
                          disabled={isProgressing}
                        >
                          {isProgressing ? (
                            <IonSpinner name="dots" />
                          ) : (
                            <div>Register</div>
                          )}
                        </IonButton>
                      </IonCol>
                      <IonCol>
                        <IonButton
                          routerLink="/otp"
                          color="dark"
                          fill="clear"
                          expand="block"
                          onClick={() => {
                            Storage.clear();
                          }}
                        >
                          Change Mobile no ?
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </form>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  ));
};
export default Register;
