import { Plugins } from "@capacitor/core";
import { RefresherEventDetail } from "@ionic/core";

import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonLoading,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonRow,
  IonSpinner,
  IonText,
} from "@ionic/react";
import Axios from "axios";
import qs from "qs";
import moment from "moment";
import React, { useEffect, useState } from "react";
import "./common.css";
import { useForm } from "react-hook-form";
import Header from "../components/Header";
import { useHistory } from "react-router-dom";
import {
  chevronDownCircleOutline,
  homeOutline,
  listOutline,
  pin,
  shareSocialOutline,
  trendingUpOutline,
} from "ionicons/icons";
import { Line } from "react-chartjs-2";
import RecaptchaInfo from "../components/RecaptchaInfo";
import SaleTable from "../components/SaleTable";
import Sinput from "../components/SInput";
import Table from "../components/Table";
import { useStore } from "../hooks/use-store";
import sAxios from "../store/helpers/axios";
import stats from "../store/helpers/const";
import { toCurrency } from "../store/helpers/functions";
import { toast } from "../store/helpers/toast";

const { Storage } = Plugins;

const Home: React.FC = () => {
  let history = useHistory();
  const { _user } = useStore();
  const [table, setTable] = useState<null | {
    email: any;
    timestamp: any;
    purchase_id: any;
  }>(null);
  function get814() {
    setProgressing(true);
    sAxios
      .post("/test", { id: _user.activeUser?.uid })
      .then((data) => {
        console.log("data share", data);
        setTable(JSON.parse(data.data.data));
      })
      .catch((e) => {
        console.log("e", e);
      })
      .finally(() => setProgressing(false));
  }

  const { handleSubmit, register, control, errors, setValue } = useForm({});
  const [isProgressing, setProgressing] = useState(false);
  const [hide, setHide] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [sales, setSales] = useState();

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    getData().finally(() => event.detail.complete());
  }

  let getData = () => {
    return new Promise((resolve, reject) => {
      sAxios
        .post("/sale_today", {
          dt: moment().format("YYYY-MM-DD"),
        })
        .then((response) => {
          console.log("response", response);
          if (response.status == 200 && response.data.status == true) {
            setSales(response.data.payload);
            resolve("done!");
          } else {
            toast("failed to fetch!!!");
          }
        })
        .catch((err: any) => {
          toast(err);
          reject(err);
        });
    });
  };

  const onSubmit = (data: any) => {
    setProgressing(true);
    //  alert(JSON.stringify(data, null, 2));

    sAxios
      .post("/g-914", {
        email: data.email,
      })
      .then((response) => {
        console.log("response final", response);
        if (response.status == 200) {
          if (response.data?.error?.length > 2) {
            setSubmitted(false);
            toast(response.data.error.join("\n"));
          } else {
            console.log("response ,going to render table");
            setSubmitted(true);
            setTable(response.data.data);
          }
        } else {
          setSubmitted(false);
          toast("error occurred");
        }
      })
      .catch((err: any) => {
        toast(err);
        // throw err;
      })
      .finally(() => setProgressing(false));
  };

  useEffect(() => {
    getData();
  }, []);

  function onSubmitCount() {
    return new Promise((resolve, reject) => {
      sAxios
        .post("/addsale", {
          email: table!.email,
          edate: moment().format("YYYY-MM-DD"),
          pdate: table!.timestamp,
          pid: table!.purchase_id,
        })
        .then((response) => {
          console.log("response", response);
          if (response.status == 200 && response.data.status == true) {
            setSales(response.data.payload);
            setValue("email", null);
            setTable(null);
            resolve("done!");
          } else if (response.status == 200 && response.data.status == false) {
            toast("Already Added to sale data!!!");
          } else {
            toast("failed to add!!!");
          }
        })
        .catch((err: any) => {
          toast(err);
          reject(err);
        });
    });
  }

  return (
    <IonPage>
      <Header title="Home" />
      <IonContent scrollX={true}>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText="Pull to refresh"
            refreshingSpinner="circles"
            refreshingText="Refreshing..."
          />
        </IonRefresher>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={isProgressing}
          message={"Please wait..."}
          spinner="lines"
        />
        <IonGrid fixed className="s-grid">
          <IonRow className="s-row" id="mh-300">
            <IonCol className="s-col">
              <div className="outbox">
                <div className="innerbox">
                  <div className="innercontent">
                    <form
                      onSubmit={handleSubmit(onSubmit)}
                      className="w-100 form"
                    >
                      <IonRow className="ion-justify-content-around">
                        <IonCol>
                          <IonList
                            lines="full"
                            className="ion-align-items-stretch"
                          >
                            <Sinput
                              name={"email"}
                              control={control}
                              errors={errors}
                              fieldName="email id"
                              type="email"
                              inputmode="email"
                            />
                          </IonList>
                        </IonCol>
                      </IonRow>
                      <IonRow className="ion-justify-content-center">
                        <IonCol>
                          <IonButton
                            className={hide ? "ion-hide" : ""}
                            fill="solid"
                            type="submit"
                            size="small"
                            expand="block"
                            disabled={isProgressing}
                          >
                            {isProgressing ? (
                              <IonSpinner name="dots" />
                            ) : (
                              <>
                                <IonIcon slot="end" icon={trendingUpOutline} />
                                fetch purchase
                              </>
                            )}
                          </IonButton>
                        </IonCol>
                      </IonRow>
                      {table?.email ? (
                        <>
                          <IonRow className="ion-justify-content-around">
                            {isSubmitted ? <Table info={table} /> : ""}
                          </IonRow>
                          <IonRow className="ion-justify-content-center">
                            <IonCol>
                              {isSubmitted ? (
                                <IonButton
                                  className={hide ? "ion-hide" : ""}
                                  fill="solid"
                                  onClick={() => onSubmitCount()}
                                  size="small"
                                  disabled={isProgressing}
                                >
                                  {isProgressing ? (
                                    <IonSpinner name="dots" />
                                  ) : (
                                    <>
                                      <IonIcon
                                        slot="end"
                                        icon={trendingUpOutline}
                                      />
                                      add to sale count
                                    </>
                                  )}
                                </IonButton>
                              ) : (
                                ""
                              )}
                            </IonCol>
                          </IonRow>
                        </>
                      ) : (
                        <>{isSubmitted ? <h4> No purchase found</h4> : ""}</>
                      )}
                      <IonItemDivider />
                      <IonRow>
                        <label className="about-margin-header">
                          Sales data for today {moment().format("DD-MMM-YYYY")}
                        </label>
                      </IonRow>
                      <IonRow className="ion-justify-content-around">
                        {sales ? <SaleTable info={sales} /> : ""}
                      </IonRow>
                      <IonRow className="ion-justify-content-around">
                        {sales ? (
                          <label>
                            Total commission for today:{" "}
                            {toCurrency(Object.values(sales!).length * 80)}
                          </label>
                        ) : (
                          ""
                        )}
                      </IonRow>
                    </form>
                  </div>
                </div>
              </div>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default Home;
