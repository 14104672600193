import {
  IonActionSheet,
  IonAvatar,
  IonBackButton,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenuButton,
  IonPopover,
  IonTitle,
  IonToolbar,
} from "@ionic/react";
import {
  arrowBackOutline,
  closeCircleOutline,
  personCircleOutline,
  star,
  walkOutline,
  walkSharp,
} from "ionicons/icons";
import { useObserver } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useStore } from "../hooks/use-store";
import { toast } from "../store/helpers/toast";

interface Sprops {
  title?: string;
}

const Header: React.FC<Sprops> = ({ title = "Title" }) => {
  const { _user } = useStore();
  const [showActionSheet, setShowActionSheet] = useState(false);
  let history = useHistory();
  useEffect(() => {
    document.title = title;
  }, [title]);
  return useObserver(() => (
    <IonHeader>
      <IonToolbar>
        <IonButtons slot="start">
          <IonBackButton text="" icon={arrowBackOutline} />
        </IonButtons>
        <IonTitle className="ion-text-center">{title}</IonTitle>
        <IonButtons slot="end">
          <IonMenuButton className={_user.menuClass} />
        </IonButtons>
      </IonToolbar>
      <IonActionSheet
        isOpen={showActionSheet}
        onDidDismiss={() => setShowActionSheet(false)}
        cssClass="my-custom-class"
        buttons={[
          {
            text: "Manage Profile",
            role: "destructive",
            icon: personCircleOutline,
            handler: () => {
              history.push("/tabs/profile");
            },
          },
          {
            text: "Log Out",
            icon: walkOutline,
            handler: () => {
              toast("Logged out successfully").then(() => {
                _user.doLogout().then(() => {
                  history.replace("/login");
                });
              });
            },
          },
          {
            text: "Cancel",
            icon: closeCircleOutline,
            role: "cancel",
            handler: () => {
              console.log("Cancel clicked");
            },
          },
        ]}
      />
    </IonHeader>
  ));
};
export default Header;
