import { Plugins } from "@capacitor/core";
// set the default values for the controls
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import Sinput from "../components/SInput";
import axios from "../store/helpers/axios";
import { toast } from "../store/helpers/toast";
import "./common.css";

const { Storage } = Plugins;
const VerifyFOTP: React.FC = () => {
  const { handleSubmit, control, errors } = useForm();
  const [isProgressing, setProgressing] = useState(false);
  const [mobile, setMobile] = useState("");
  const [counter, setCounter] = useState(120);
  const history = useHistory();
  const _doLogin = async (data: any) => {
    setProgressing(true);
    axios
      .post("/verifyOTP", {
        otp: data.otp,
        mobile: mobile,
      })
      .then((response: any) => {
        if (response.data.status) {
          Storage.set({
            key: "page",
            value: "reset",
          }).then(() => history.replace("/reset"));
        } else {
          toast(response.data.message);
        }
      })
      .catch((err: any) => {
        toast(err);
        Storage.clear();
        throw err;
      })
      .finally(() => {
        setProgressing(false);
      });
  };
  useEffect(() => {
    if (!counter || counter < 0) return;
    const intervalId = setInterval(() => {
      setCounter(counter - 1);
    }, 1000);
    return () => clearInterval(intervalId);
  }, [counter]);

  useEffect(() => {
    Storage.get({ key: "page" }).then((data: any) => {
      console.log("page-->", data.value);
      switch (data.value) {
        case "verifyFOTP": {
          Storage.get({ key: "mobile" }).then((data: any) => {
            if (data.value) {
              setMobile(JSON.parse(data.value).mobile);
            } else {
              Storage.clear().then(() => history.replace("/verifyFOTP"));
            }
          });
          break;
        }
        case "reset": {
          toast("Your OTP is already verified").then(() =>
            history.replace("/reset")
          );
          break;
        }
        default: {
          Storage.clear().then(() => history.replace("/verifyFOTP"));
          break;
        }
      }
    });
  }, []);
  const onSubmit = (data: any) => {
    _doLogin(data);
  };
  return (
    <IonPage>
      <IonContent>
        <IonGrid fixed className="h-100">
          <IonRow className="h-100">
            <IonCol className="ion-justify-content-center  s-column">
              <IonRow className="ion-align-items-stretch">
                <IonCol size="12">
                  <div className="ion-text-center">
                    <img
                      className="logo-c ion-justify-content-center"
                      src="assets/logo/scs.svg"
                      alt="scs logo"
                    />
                  </div>
                </IonCol>
              </IonRow>
              <IonRow className="ion-align-items-stretch ion-nowrap">
                <IonCol size="12">
                  <div className="center-text">
                    <span>VERIFY YOUR 4 DIGIT OTP</span>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="ion-align-self-stretch">
                  <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <IonRow className="ion-align-items-stretch">
                      <IonCol className="ion-align-self-stretch">
                        <IonList>
                          <IonItem>
                            <IonLabel color="dark" position="fixed">
                              Your Mobile
                            </IonLabel>
                            <IonInput disabled value={mobile} />
                          </IonItem>
                          <Sinput
                            name="otp"
                            control={control}
                            errors={errors}
                            fieldName="OTP"
                            inputmode="otp"
                          />
                        </IonList>
                      </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-stretch">
                      <IonCol>
                        <IonButton
                          fill="solid"
                          type="submit"
                          size="small"
                          expand="block"
                          disabled={isProgressing}
                        >
                          {isProgressing ? (
                            <IonSpinner name="dots" />
                          ) : (
                            <div>Verify OTP</div>
                          )}
                        </IonButton>
                      </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-self-center">
                      <IonCol>
                        <IonButton
                          routerLink="/forgot"
                          color="dark"
                          fill="clear"
                          expand="block"
                          onClick={() => {
                            Storage.clear();
                          }}
                          disabled={counter > 0}
                        >
                          {counter > 0
                            ? `wait till ${counter}s for OTP resend`
                            : `Didn't get the OTP , resend it`}
                        </IonButton>
                      </IonCol>
                      <IonCol>
                        <IonButton
                          routerLink="/forgot"
                          color="dark"
                          fill="clear"
                          expand="block"
                          onClick={() => {
                            Storage.clear();
                          }}
                        >
                          Change mobile number ?
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </form>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default VerifyFOTP;
