import {configure} from 'mobx'
import {UserStore} from './userStore'

configure({enforceActions: 'observed'}) // action 밖에서 state 수정 비허용
class RootStore {
    _user: UserStore

    constructor() {
        this._user = new UserStore()
    }
}

export default RootStore
