import { Plugins } from "@capacitor/core";
// set the default values for the controls
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import Sinput from "../components/SInput";
import axios from "../store/helpers/axios";
import { toast } from "../store/helpers/toast";
import "./common.css";
import moment from "moment";

const { Storage } = Plugins;
const ResetPassword: React.FC = () => {
  const [mobile, setMobile] = useState("");
  const { handleSubmit, control, errors } = useForm();
  const [isProgressing, setProgressing] = useState(false);
  const history = useHistory();
  useEffect(() => {
    Storage.get({ key: "page" }).then((data: any) => {
      console.log("page forward!", data.value);
      switch (data.value) {
        case "verifyOTP": {
          history.replace("/verifyOTP");
          break;
        }
        case "reset": {
          Storage.get({ key: "mobile" }).then((data: any) => {
            console.log(
              "useEffect has been called, mobile is !",
              JSON.parse(data.value)
            );
            if (data.value) {
              setMobile(JSON.parse(data.value).mobile);
            } else {
              Storage.clear().then(() => history.replace("/forgot"));
            }
          });
          break;
        }
        default: {
          Storage.clear().then(() => history.replace("/forgot"));
          break;
        }
      }
    });
  }, []);
  const _doLogin = async (data: any) => {
    setProgressing(true);
    axios
      .post("/updatePassword", {
        mobile: mobile,
        password: data.password,
        updated_date: moment().format("YYYY-MM-DD HH:mm:ss"),
      })
      .then((response: any) => {
        if (response.data.status) {
          toast("password has been successfully rest, please login").then(
            () => {
              history.replace("/login");
            }
          );
        } else {
          toast(response.data.message);
        }
      })
      .catch((err: any) => {
        toast(err);
        // this.errors = err.response && err.response.body && err.response.body.errors;
        throw err;
      })
      .finally(() => {
        setProgressing(false);
        console.log("finally bye to store");
      });
  };
  const onSubmit = async (data: any) => {
    await _doLogin(data);
  };
  return (
    <IonPage>
      <IonContent>
        <IonGrid fixed className="h-100">
          <IonRow className="h-100">
            <IonCol className="ion-justify-content-center  s-column">
              <IonRow className="ion-align-items-stretch">
                <IonCol size="12">
                  <div className="ion-text-center">
                    <img
                      className="logo-c ion-justify-content-center"
                      src="assets/logo/scs.svg"
                      alt="scs logo"
                    />
                  </div>
                </IonCol>
              </IonRow>
              <IonRow className="ion-align-items-stretch ion-nowrap">
                <IonCol size="12">
                  <div className="center-text">
                    <span>RESET PASSWORD</span>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="ion-align-self-stretch">
                  <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <IonRow className="ion-align-items-stretch">
                      <IonCol className="ion-align-self-stretch">
                        <IonList>
                          <IonItem>
                            <IonLabel color="dark" position={"fixed"}>
                              Your Mobile
                            </IonLabel>
                            <IonInput disabled value={mobile} />
                          </IonItem>
                          <Sinput
                            name="password"
                            control={control}
                            errors={errors}
                            fieldName="New Password"
                            inputmode="password"
                          />
                        </IonList>
                      </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-stretch">
                      <IonCol>
                        <IonButton
                          fill="solid"
                          type="submit"
                          size="small"
                          expand="block"
                          disabled={isProgressing}
                        >
                          {isProgressing ? (
                            <IonSpinner name="dots" />
                          ) : (
                            <div>ResetPassword</div>
                          )}
                        </IonButton>
                      </IonCol>
                      <IonCol>
                        <IonButton
                          routerLink="/login"
                          color="dark"
                          fill="clear"
                          expand="block"
                        >
                          Login Now
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </form>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default ResetPassword;
