export const toCurrencDecimal = (num: number) =>
  num
    ? new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
      }).format(num)
    : 0;
export const toCurrency = (num: number) =>
  num
    ? new Intl.NumberFormat("en-IN", {
        style: "currency",
        currency: "INR",
        minimumFractionDigits: 0,
      }).format(Math.round(num))
    : 0;
export const presentValue = (fv: number, rate: number, periods: number) =>
  fv / Math.pow(1 + rate / 100, periods);
export const futureValue = (pv: number, rate: number, periods: number) =>
  pv * Math.pow(1 + rate / 100, periods);
export const fdCalculator = (p: number, r: number, n: number) => {
  let interest = 0;
  let principal = p;
  for (let i = 0; i < n; i++) {
    interest = principal * Math.pow(1 + r / 400, 4) - principal;
    principal =
      interest > 10000 ? interest * 0.9 + principal : interest + principal;
  }
  return principal;
};
export const fdrdCalculator = (
  p1: number,
  p2: number,
  r: number,
  n: number
) => {
  let interest = 0;
  let principal = p1;
  for (let i = 0; i < n; i++) {
    //console.log(`${i} --year principal-->${principal} interest--->${interest}`);
    interest = principal * Math.pow(1 + r / 400, 4) - principal;
    principal =
      interest > 10000
        ? interest * 0.9 + principal + p2
        : interest + principal + p2;
  }
  return principal;
};
