import { Plugins } from "@capacitor/core";
import {
  appsOutline,
  buildOutline,
  createOutline,
  gitBranchOutline,
  home,
  logOutOutline,
  personCircleOutline,
} from "ionicons/icons";
import { action, autorun, observable, runInAction } from "mobx";
import { UserModel } from "../model/UserModel";
import stats from "./helpers/const";
import { toast } from "./helpers/toast";

const { Storage } = Plugins;
const { Network } = Plugins;

interface AppPage {
  url: string;
  icon: string;
  title: string;
}

export class UserStore {
  @observable isFinished = false;
  @observable activeUser: null | UserModel = null;
  @observable authCheckComplete = false;
  @observable menuClass = "c-normal";
  @observable currPage: string = "";
  currMenu: AppPage[] = [];
  topMenu: AppPage[] = [
    {
      title: "Home",
      url: "/home",
      icon: home,
    },
  ];
  bottomMenu: AppPage[] = [
    {
      title: "Log Off",
      url: "/logout",
      icon: logOutOutline,
    },
  ];
  adminMenu1: AppPage[] = [
    {
      title: "Add purchase",
      url: "/addPurchase",
      icon: logOutOutline,
    },
  ];

  adminMenu2: AppPage[] = [
    {
      title: "Delete purchase",
      url: "/deletePurchase",
      icon: logOutOutline,
    },
  ];
  tempMenu: AppPage[][] = [
    [
      {
        title: "Sales for any date",
        url: "/sales",
        icon: gitBranchOutline,
      },
      {
        title: "Manual Sale Entry",
        url: "/manual",
        icon: createOutline,
      },
    ],
  ];
  @observable
  appPages: AppPage[] = this.topMenu
    .concat(this.currMenu)
    .concat(this.bottomMenu);

  @action
  setMenu(i: number) {
    if (this.tempMenu.length > i) {
      this.currMenu = this.tempMenu[i];
      this.appPages = this.topMenu
        .concat(this.currMenu)
        .concat(this.bottomMenu);
    }
    if (this.activeUser?.role_id == 1) {
      this.appPages = this.appPages
        .concat(this.adminMenu1)
        .concat(this.adminMenu2);
    }
  }

  constructor() {
    this.isFinished = true;
    this.initializeStore();
  }

  @action setCurrPage(page: string) {
    this.currPage = page;
  }

  @action setUser = async (user: UserModel) => {
    this.activeUser = user;
    this.authCheckComplete = true;
    await this.setUserDB(user);
  };
  setUserDB = (user: UserModel) => {
    Storage.set({
      key: "user",
      value: JSON.stringify(user),
    });
  };
  @action setMenuClass = (c: string) => {
    this.menuClass = c;
  };
  @action setUserData = (user: UserModel) => {
    if (user.company) {
      this.activeUser!.company = user.company;
    }
    if (user.designation) {
      this.activeUser!.designation = user.designation;
    }

    this.getUser()
      .then((db: any) => {
        let usr = JSON.parse(db.value);
        usr.company = this.activeUser!.company;
        usr.designation = this.activeUser!.designation;
        if (usr) {
          this.setUserDB(usr);
        }
      })
      .catch((e) => {
        toast(e);
      });
  };
  @action setProfilePic = (path: String) => {
    this.activeUser!.profilePic =
      stats.server + "/" + this.activeUser?.mobile + "/" + path;
    this.getUser()
      .then((db: any) => {
        let usr = JSON.parse(db.value);
        usr.profilePic = this.activeUser!.profilePic;
        if (usr) {
          this.setUserDB(usr);
        }
      })
      .catch((e) => {
        toast(e);
      });
  };

  @action setLoading(status: boolean) {
    this.isFinished = status;
  }

  dis = autorun(() => {
    console.log("authCheckComplete", this.authCheckComplete);
  });

  async getUser() {
    return await Storage.get({ key: "user" });
  }

  @action doLogout = async () => {
    this.activeUser = null;
    this.authCheckComplete = false;
    Storage.clear().then(() => {
      console.log("logged out successfully");
    });
  };

  async initializeStore() {
    this.getUser()
      .then((user: any) => {
        const usr = JSON.parse(user.value);
        if (usr) {
          this.setUser(usr);
          runInAction(() => {
            this.authCheckComplete = true;
          });
        } else {
          runInAction(() => {
            this.authCheckComplete = false;
          });
        }
      })
      .catch((e) => {
        toast(e);
      });
  }
}
