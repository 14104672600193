import { Plugins } from "@capacitor/core";
// set the default values for the controls
import { ErrorMessage } from "@hookform/error-message";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonRouterContext,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import { useObserver } from "mobx-react-lite";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useHistory } from "react-router";
import Sinput from "../components/SInput";
import { useStore } from "../hooks/use-store";
import { UserModel } from "../model/UserModel";
import axios from "../store/helpers/axios";
import stats from "../store/helpers/const";
import { toast } from "../store/helpers/toast";
import "./common.css";

const { Storage } = Plugins;
const Login: React.FC = () => {
  const { _user } = useStore();
  const { handleSubmit, control, errors } = useForm();
  const [isProgressing, setProgressing] = useState(false);
  const [unMounting, setUnmounting] = useState(false);
  const history = useHistory();

  useEffect(() => {
    setUnmounting(false);

    return () => {
      setUnmounting(true);
    };
  }, []);

  const _doLogin = async (data: any) => {
    setProgressing(true);
    axios
      .post("/signin", {
        mobile: data.mobile,
        password: data.password,
      })
      .then((response: any) => {
        if (response.data.status) {
          let user: UserModel = {
            id: response.data.payload.id,
            profilePic: response.data.payload?.profilepath
              ? stats.server +
                "/" +
                response.data.payload.mobile +
                "/" +
                response.data.payload?.profilepath
              : undefined,
            name: response.data.payload.name,
            email: response.data.payload.email,
            mobile: response.data.payload.mobile,
            company: response.data.payload?.company,
            designation: response.data.payload?.designation,
            status: response.data.payload.status,
            role_id: response.data.payload.role_id,
            uid: response.data.payload.uid,
          };
          Storage.clear().then(() => {
            Storage.set({
              key: "token",
              value: response.data.token,
            }).then(() => {
              _user.setUser(user).then(() => {
                history.replace("/home");
              });
            });
          });
        } else {
          toast(response.data.message);
          if (!unMounting) {
            setProgressing(false);
          }
        }
      })
      .catch((err: any) => {
        toast(err).then(() => setProgressing(false));
        throw err;
      });
  };

  const onSubmit = (data: any) => {
    _doLogin(data);
  };
  return useObserver(() => (
    <IonPage>
      <IonContent>
        <IonGrid fixed className="h-100">
          <IonRow className="h-100">
            <IonCol className="ion-justify-content-center  s-column">
              <IonRow className="ion-align-items-stretch">
                <IonCol size="12">
                  <div className="ion-text-center">
                    <img
                      className="logo-c ion-justify-content-center"
                      src="assets/logo/scs.svg"
                      alt="scs logo"
                    />
                  </div>
                </IonCol>
              </IonRow>
              <IonRow className="ion-align-items-stretch ion-nowrap">
                <IonCol size="12">
                  <div className="center-text">
                    <span>Login form</span>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="ion-align-self-stretch">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <IonRow className="ion-align-items-stretch">
                      <IonCol className="ion-align-self-stretch">
                        <IonList>
                          <Sinput
                            name="mobile"
                            control={control}
                            errors={errors}
                            fieldName="Mobile without 91"
                            inputmode="tel"
                          />
                          <IonItem className="ion-item">
                            <IonLabel color="medium" position="floating">
                              password
                              <ErrorMessage
                                errors={errors}
                                name="password"
                                as={<div className="l-label" />}
                              />
                            </IonLabel>
                            <Controller
                              render={({ onChange, onBlur, value }) => (
                                <IonInput onIonChange={onChange} />
                              )}
                              control={control}
                              name="password"
                              rules={{
                                required: "This is a required field",
                              }}
                            />
                          </IonItem>
                        </IonList>
                      </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-stretch">
                      <IonCol>
                        <IonButton
                          fill="solid"
                          type="submit"
                          size="small"
                          expand="block"
                          disabled={isProgressing}
                        >
                          {isProgressing ? (
                            <IonSpinner name="dots" />
                          ) : (
                            <div>Login</div>
                          )}
                        </IonButton>
                      </IonCol>
                      <IonCol>
                        <IonButton
                          routerLink="/forgot"
                          color="dark"
                          fill="clear"
                          expand="block"
                        >
                          Forgot password
                        </IonButton>
                      </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-self-center">
                      <IonCol>
                        <IonButton
                          routerLink="/otp"
                          color="dark"
                          fill="clear"
                          expand="block"
                        >
                          New User ? Register Now
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </form>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  ));
};
export default Login;
