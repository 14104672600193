import { IonCol } from "@ionic/react";
import React from "react";
import { toCurrency } from "../store/helpers/functions";
import moment from "moment";

const Thead = (props: any) => {
  return (
    <thead key={props.k}>
      <tr key={props.k}>
        {Object.values(props.y).map((z: any, i: number) => (
          <th key={i}> {z}</th>
        ))}
      </tr>
    </thead>
  );
};
const SubTable = (props: any) => {
  return props ? (
    <table id="innerTable">
      <thead>
        <tr>
          <th>Mode</th>
          <th>Premium</th>
          <th>
            with GST
            <br />
            (1st year)
          </th>
          <th>
            with GST
            <br />
            (2nd yr onwards)
          </th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>Yearly</td>
          <td>{toCurrency(props?.yrly?.total)}</td>
          <td>{toCurrency(props?.yrly?.total * 1.045)}</td>
          <td>{toCurrency(props?.yrly?.total * 1.0225)}</td>
        </tr>
        <tr>
          <td>Half Yrly</td>
          <td>{toCurrency(props?.hyrly?.total)}</td>
          <td>{toCurrency(props?.hyrly?.total * 1.045)}</td>
          <td>{toCurrency(props?.hyrly?.total * 1.0225)}</td>
        </tr>
        <tr>
          <td>Quarterly</td>
          <td>{toCurrency(props?.qtrly?.total)}</td>
          <td>{toCurrency(props?.qtrly?.total * 1.045)}</td>
          <td>{toCurrency(props?.qtrly?.total * 1.0225)}</td>
        </tr>
        <tr>
          <td>Monthly</td>
          <td>{toCurrency(props?.mnthly?.total)}</td>
          <td>{toCurrency(props?.mnthly?.total * 1.045)}</td>
          <td>{toCurrency(props?.mnthly?.total * 1.0225)}</td>
        </tr>
      </tbody>
    </table>
  ) : (
    <table />
  );
};
const Table: React.FC<any> = ({ info }) => {
  return info ? (
    <IonCol className="over-flow" size="12">
      <table>
        <tbody id="tb">
          <tr>
            <td>Purchase ref no</td>
            <td className="ion-text-end">{info?.purchase_id}</td>
          </tr>
          <tr>
            <td>email</td>
            <td className="ion-text-end">{info?.email}</td>
          </tr>
          <tr>
            <td>time</td>
            <td className="ion-text-end">
              {moment(parseInt(info?.timestamp)).format("DD MMM YYYY hh:mm a")}
            </td>
          </tr>
        </tbody>
      </table>
    </IonCol>
  ) : (
    <table />
  );
};
export default Table;
