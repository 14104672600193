import { ErrorMessage } from "@hookform/error-message";
import { IonIcon, IonInput, IonItem, IonLabel } from "@ionic/react";
import React from "react";
import { Controller } from "react-hook-form";

interface Sprops {
  name: string;
  control: any;
  errors: any;
  required?: boolean;
  min?: any;
  max?: any;
  fieldName: string;
  autocomplete?: boolean;
  inputmode?: string;
  type?: string;
  disabled?: boolean;
  icon?: any;
  defaultValue?: any;
  minVal?: any;
  maxVal?: any;
  onChange?: any;
}

const Sinput: React.FC<Sprops> = ({
  fieldName,
  control,
  name,
  errors,
  required,
  min,
  max,
  minVal,
  maxVal,
  type,
  inputmode,
  autocomplete = "off",
  disabled,
  icon,
  defaultValue,
  onChange,
}) => {
  let rulesOBJ: any = {};
  let props: any = {};
  props.autocomplete = autocomplete;
  const showErr = (_fieldName: string) => {
    return (
      <ErrorMessage
        errors={errors}
        name={_fieldName}
        as={<div className="l-label" />}
      />
    );
  };
  if (required) {
    rulesOBJ.required = "required field";
  }
  if (minVal) {
    rulesOBJ.min = {
      value: minVal,
      message: "Minimum value is " + minVal + "",
    };
  }
  if (maxVal) {
    rulesOBJ.max = {
      value: maxVal,
      message: "Maximum value is " + maxVal + "",
    };
  }
  const setMinLength = (min: number) => {
    rulesOBJ.required = "required field";
    rulesOBJ.minLength = {
      value: min,
      message: "Minimum " + min + " character required",
    };
  };
  const setMaxLen = (max: number) => {
    rulesOBJ.maxLength = {
      value: max,
      message: "Maximum " + max + " allowded",
    };
  };
  if (min) {
    setMinLength(min);
  }
  if (max) {
    setMaxLen(max);
  }
  if (disabled) {
    props.disabled = disabled;
  }
  switch (inputmode) {
    case "tel": {
      props.inputmode = "tel";
      props.minlength = 10;
      props.maxlength = 10;
      setMaxLen(10);
      setMinLength(10);
      rulesOBJ.pattern = {
        value: /^[0-9]{10}$/i,
        message: "only numberes are allowded",
      };
      break;
    }
    case "otp": {
      props.inputmode = "numeric";
      props.type = "number";
      props.minlength = 4;
      props.maxlength = 4;
      setMaxLen(4);
      setMinLength(4);
      rulesOBJ.pattern = {
        value: /^[0-9]{4}$/i,
        message: "only numberes are allowded",
      };
      break;
    }
    case "url": {
      props.inputmode = "url";
      break;
    }
    case "email": {
      props.inputmode = "email";
      props.type = "email";
      rulesOBJ.pattern = {
        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        message: "invalid email address",
      };
      setMinLength(9);
      setMaxLen(150);
      break;
    }
    case "numeric": {
      props.inputmode = "numeric";
      props.type = "number";
      break;
    }
    case "decimal": {
      props.inputmode = "decimal";
      break;
    }
    case "password": {
      props.inputmode = "text";
      setMinLength(4);
      setMaxLen(50);
      break;
    }
    default: {
      props.inputmode = "text";
      props.type = "text";
      break;
    }
  }
  switch (type) {
    case "number": {
      props.type = "number";
      break;
    }
    case "password": {
      props.type = "password";
      break;
    }
    case "email": {
      props.type = "email";
      break;
    }
    case "tel": {
      props.type = "tel";
      break;
    }
    case "url": {
      props.type = "url";
      break;
    }
    case "date": {
      props.type = "date";
      break;
    }
    default: {
      props.type = "text";
      break;
    }
  }
  return (
    <IonItem className="ion-item">
      <IonIcon className="s-middle" slot="end" icon={icon ? icon : ""} />{" "}
      <IonLabel
        className={rulesOBJ.required ? "required" : "notreq"}
        color="medium"
        position="floating"
      >
        {fieldName} {showErr(name)}
      </IonLabel>
      <Controller
        control={control}
        name={name}
        rules={rulesOBJ}
        defaultValue={defaultValue}
        render={({ onChange, onBlur, value, name }) => (
          <IonInput
            onIonBlur={onBlur}
            onIonChange={onChange}
            onChange={onChange}
            value={value}
            name={name}
            {...props}
          />
        )}
      />
    </IonItem>
  );
};
export default Sinput;
