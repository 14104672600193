const stats = {
  //server: "http://127.0.0.1:8082",
  server: "https://node.scstechnolabs.com",

  cardOptions: [
    { value: 1, label: "stripe" },
    { value: 2, label: "razorpay" },
    { value: 3, label: "razorpay discount" },
  ],
  Plans: [
    { value: 1, label: "landscape" },
    { value: 2, label: "portrate" },
    { value: 3, label: "coloured" },
  ],
};

export default stats;
