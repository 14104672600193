import { Plugins } from "@capacitor/core";
import { RefresherEventDetail } from "@ionic/core";
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonIcon,
  IonLabel,
  IonList,
  IonPage,
  IonRow,
  IonSegment,
  IonSegmentButton,
  IonSlide,
  IonSlides,
  IonSpinner,
  IonRefresher,
  IonRefresherContent,
  IonLoading,
} from "@ionic/react";
import {
  heart,
  home,
  pin,
  trendingUpOutline,
  chevronDownCircleOutline,
} from "ionicons/icons";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { RouteComponentProps } from "react-router";
import { useHistory, useLocation } from "react-router-dom";
import Analytics from "../../components/Analytics";
import Header from "../../components/Header";
import RecaptchaInfo from "../../components/RecaptchaInfo";
import Sage from "../../components/SAge";
import Scheckbox from "../../components/Scheckbox";
import Sinput from "../../components/SInput";
import Table from "../../components/Table";
import { useStore } from "../../hooks/use-store";
import axios from "../../store/helpers/axios";
import { toast } from "../../store/helpers/toast";
import "../common.css";

const { Storage } = Plugins;

interface GameProps
  extends RouteComponentProps<{
    id: string;
  }> {}

const P914: React.FC<GameProps> = ({ match }) => {
  let history = useHistory();
  const { _user } = useStore();
  const [hide, setHide] = useState(false);
  const [table, setTable] = useState();
  const [plan, setPlan] = useState<string>("914");
  const [pterm, setPterm] = useState(1);
  const [rSumVisibility, setRSumVisibility] = useState(false);
  const { handleSubmit, register, control, errors, setValue } = useForm({});
  const [title, setTitle] = useState("Plan");
  const [index, setIndex] = useState<any>(0);
  const [isProgressing, setProgressing] = useState(false);
  const [isSubmitted, setSubmitted] = useState(false);
  const [validation, setValidation] = useState<any>({});
  type LocationState = {
    plan: string;
  };
  const location = useLocation<LocationState>();

  useEffect(() => {
    console.log("match.params.id", match.params.id);
  }, [match.params.id]);

  useEffect(() => {
    if (location?.state?.plan) {
    }
  }, [location]);

  const getPlanDetails = async (p: any) => {
    setProgressing(true);
    // result: '?query=abc'
    axios
      .post("/g-plan", { plan: p ?? plan })
      .then((data) => {
        if (data.data?.data["0"]) {
          setHide(false);
          const val = data.data?.data["0"];
          console.log("data val", val);
          if (val.CondTerm) {
            try {
              const term = JSON.parse(val.CondTerm);
              if (term === 1) {
                setPterm(term);
              } else {
              }
            } catch (e) {}
          }
          setTitle(val.PlanName);
          setValidation({
            MinAge: val.MinAge,
            MaxAge: val.MaxAge,
            MinTerm: val.MinTerm,
            MaxTerm: val.MaxTerm,
            MinSum: val.MinSum,
            MaxSum: val.MaxSum == 0 ? 999999999999999 : val.MaxSum,
          });
        } else {
          setHide(true);
          setTitle("invalid plan");
        }
      })
      .catch((e) => {
        setTitle("ERORR!!!");
        toast("erorr!!! " + e.message);
      })
      .finally(() => {
        setProgressing(false);
        return true;
      });
  };

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    getPlanDetails(plan).finally(() => event.detail.complete());
  }
  const slideOpts = {
    initialSlide: 0,
    speed: 400,
    slidesPerView: 1,
    centeredSlides: true,
    watchSlidesVisibility: true,
    watchSlidesProgress: true,
    pagination: { type: "progressbar" },
  };
  const mySlides = useRef(document.createElement("ion-slides"));

  const handleSlidesLoad = (e: any) => {
    mySlides.current.getActiveIndex().then((i) => setIndex(i));
  };

  const onSubmit = (data: any) => {
    setProgressing(true);
    //  alert(JSON.stringify(data, null, 2));

    axios
      .post("/g-914", {
        email: data.email,
      })
      .then((response) => {
        console.log("response final", response);
        if (response.status == 200) {
          if (response.data?.error?.length > 2) {
            setSubmitted(false);
            toast(response.data.error.join("\n"));
          } else {
            console.log("response ,going to render table");
            setSubmitted(true);
            setTable(response.data.data);
          }
        } else {
          setSubmitted(false);
          toast("error occurred");
        }
      })
      .catch((err: any) => {
        toast(err);
        // throw err;
      })
      .finally(() => setProgressing(false));
  };
  return (
    <IonPage>
      <Header title={title} />
      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText="Pull to refresh"
            refreshingSpinner="lines"
            refreshingText="Refreshing..."
          />
        </IonRefresher>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={isProgressing}
          message={"Please wait..."}
          spinner="lines"
        />
        <IonGrid fixed className="h-grid">
          <div className="outbox">
            <div className="innerbox">
              <div className="innercontent">
                <form onSubmit={handleSubmit(onSubmit)} className="w-100 form">
                  <IonRow className="ion-justify-content-around">
                    <IonCol>
                      <IonList lines="full" className="ion-align-items-stretch">
                        <Sinput
                          name={"email"}
                          control={control}
                          errors={errors}
                          fieldName="email id"
                          type="email"
                          inputmode="email"
                        />
                      </IonList>
                    </IonCol>
                  </IonRow>
                  <IonRow className="ion-justify-content-center">
                    <IonCol>
                      <IonButton
                        className={hide ? "ion-hide" : ""}
                        fill="solid"
                        type="submit"
                        size="small"
                        expand="block"
                        disabled={isProgressing}
                      >
                        {isProgressing ? (
                          <IonSpinner name="dots" />
                        ) : (
                          <>
                            <IonIcon slot="end" icon={trendingUpOutline} />
                            Calculate
                          </>
                        )}
                      </IonButton>
                    </IonCol>
                  </IonRow>
                  <IonRow className="ion-justify-content-around">
                    {isSubmitted ? <Table info={table} /> : ""}
                  </IonRow>
                </form>
                <RecaptchaInfo />
              </div>
            </div>
          </div>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default P914;
