import { ErrorMessage } from "@hookform/error-message";
import { IonDatetime, IonItem, IonLabel } from "@ionic/react";
import React from "react";
import { Controller } from "react-hook-form";

interface Sprops {
  name: string;
  control: any;
  errors: any;
  required?: boolean;
  min?: any;
  max?: any;
  fieldName: string;
}

const Sdate: React.FC<Sprops> = ({
  fieldName,
  control,
  name,
  errors,
  required,
  min,
  max,
}) => {
  const showErr = (_fieldName: string) => {
    return (
      <ErrorMessage
        errors={errors}
        name={_fieldName}
        as={<div className="l-label" />}
      />
    );
  };
  let obj: any = {};
  if (required) {
    obj.required = "required field";
  }

  return (
    <IonItem className="ion-item">
      <IonLabel
        color="medium"
        className={obj.required ? "required" : "notreq"}
        position="floating"
      >
        {fieldName}
        {showErr(name)}
      </IonLabel>
      <Controller
        control={control}
        name={name}
        rules={obj}
        render={({ onChange, onBlur, value, name }) => (
          <IonDatetime
            displayFormat="DD-MM-YYYY"
            pickerFormat="DD-MM-YYYY"
            min={min}
            max={max}
            onIonBlur={onBlur}
            onIonChange={onChange}
            value={value}
            name={name}
          />
        )}
      />
    </IonItem>
  );
};

export default Sdate;
