import { Plugins } from "@capacitor/core";
// set the default values for the controls
import {
  IonButton,
  IonCol,
  IonContent,
  IonGrid,
  IonList,
  IonPage,
  IonRow,
  IonSpinner,
} from "@ionic/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router";
import Sinput from "../components/SInput";
import axios from "../store/helpers/axios";
import { toast } from "../store/helpers/toast";
import "./common.css";
import moment from "moment";

const { Storage } = Plugins;
const SendOTP: React.FC = () => {
  const { handleSubmit, control, errors } = useForm();
  const [isProgressing, setProgressing] = useState(false);
  const history = useHistory();
  useEffect(() => {
    Storage.get({ key: "mobile" }).then((data: any) => {
      console.log("mobile is-->", data.value);
      if (data.value) {
        let d = JSON.parse(data.value);
        const duration = moment
          .duration(moment(new Date()).diff(moment(d.date)))
          .asMinutes();
        if (duration < 60) {
          toast(
            `You have already the OTP ,send ${duration.toFixed(
              2
            )} minutes before`
          ).then(() => history.push("/verifyOTP"));
        } else {
          toast("Your OTP is expired").then(() => {
            Storage.clear();
          });
        }
      }
    });
  }, []);
  const _doLogin = async (data: any) => {
    setProgressing(true);
    Storage.set({
      key: "mobile",
      value: JSON.stringify({ mobile: data.mobile, date: moment().format() }),
    }).then(() => {
      Storage.set({
        key: "page",
        value: "verifyOTP",
      });
      axios
        .post("/sendOTP", {
          mobile: data.mobile,
        })
        .then((response: any) => {
          if (response.data.status) {
            toast(`OTP has been send to your mobile`).then(() => {
              history.push("/verifyOTP");
            });
          } else {
            toast(response.data.message).then(() => Storage.clear());
          }
        })
        .catch((err: any) => {
          toast(err).then(() => Storage.clear());
          throw err;
        })
        .finally(() => {
          setProgressing(false);
        });
    });
  };
  const onSubmit = (data: any) => {
    _doLogin(data);
  };
  return (
    <IonPage>
      <IonContent>
        <IonGrid fixed className="h-100">
          <IonRow className="h-100">
            <IonCol className="ion-justify-content-center  s-column">
              <IonRow className="ion-align-items-stretch">
                <IonCol size="12">
                  <div className="ion-text-center">
                    <img
                      className="logo-c ion-justify-content-center"
                      src="assets/logo/scs.svg"
                      alt="scs logo"
                    />
                  </div>
                </IonCol>
              </IonRow>
              <IonRow className="ion-align-items-stretch ion-nowrap">
                <IonCol size="12">
                  <div className="center-text">
                    <span>GENERATE OTP</span>
                  </div>
                </IonCol>
              </IonRow>
              <IonRow>
                <IonCol className="ion-align-self-stretch">
                  <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <IonRow className="ion-align-items-stretch">
                      <IonCol className="ion-align-self-stretch">
                        <IonList>
                          <Sinput
                            name="mobile"
                            control={control}
                            errors={errors}
                            fieldName="Mobile without 91"
                            inputmode="tel"
                            type="tel"
                          />
                        </IonList>
                      </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-items-stretch">
                      <IonCol>
                        <IonButton
                          fill="solid"
                          type="submit"
                          size="small"
                          expand="block"
                          disabled={isProgressing}
                        >
                          {isProgressing ? (
                            <IonSpinner name="dots" />
                          ) : (
                            <div>Send OTP</div>
                          )}
                        </IonButton>
                      </IonCol>
                    </IonRow>
                    <IonRow className="ion-align-self-center">
                      <IonCol>
                        <IonButton
                          routerLink="/login"
                          color="dark"
                          fill="clear"
                          expand="block"
                        >
                          Already Registered? Login Now
                        </IonButton>
                      </IonCol>
                    </IonRow>
                  </form>
                </IonCol>
              </IonRow>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};
export default SendOTP;
